import React, { useContext, useMemo } from 'react';
import BettingButtons from 'components/Dashboard/BettingButtons';
import ChosePlayersModal from 'components/Modals/ChosePlayersModal';
import TournamentContext from 'context/TournamentContext';
import useFetch from 'hooks/useFetch';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import { HAND_STATE_URL } from 'constants/apiRoutes';
import TopicAnnouncementButton from 'components/Buttons/TopicAnnouncementButton';
import QuestionAnnouncementButton from 'components/Buttons/QuestionAnnouncementButton';
import AuthContext from 'context/AuthContext';
import ChoseQuestionNumberModal from 'components/Modals/ChoseQuestionNumberModal';
import EnterAnswer from 'components/Dashboard/EnterAnswer';
import errorToast from 'components/toasts/errorToast';
import TimeLeftToAnswerProgress from 'components/Progress/TimeLeftToAnswerProgress';

function Controls() {
  const {
    tournament,
    setIsTournamentChangingState,
    isTournamentChangingState,
  } = useContext(TournamentContext);
  const { stateId } = tournament.handState;
  const { user } = useContext(AuthContext);

  const {
    fetchData,
  } = useFetch(`${HAND_STATE_URL}/${tournament.uniqueId}`, {
    method: 'PATCH',
    fetchOnMount: false,
    onError: (message) => {
      errorToast(message);
    },
  });

  const triggerNextTournamentState = ({
    question, questionTopic, standardQuestionNumber, tiebreakQuestionNumber, tiebreakQuestion,
  }) => {
    fetchData({
      ...(question !== null && { question }),
      ...(questionTopic !== null && { question_topic: questionTopic }),
      ...(standardQuestionNumber !== null && { standard_question_number: standardQuestionNumber }),
      ...(tiebreakQuestionNumber !== null && { tiebreak_question_number: tiebreakQuestionNumber }),
      ...(tiebreakQuestion !== null && { tiebreak_question: tiebreakQuestion }),

    });
  };

  const standardQuestions = useMemo(() => {
    if (!tournament.questions) {
      return [];
    }

    return tournament.questions.filter((question) => question.type === 'standard');
  }, [tournament.questions]);

  const tiebreakQuestions = useMemo(() => {
    if (!tournament.questions) {
      return [];
    }

    return tournament.questions.filter((question) => question.type === 'tiebreak');
  }, [tournament.questions]);

  return (
    <div className="controls_holder">
      {stateId === TOURNAMENT_STATES.TOPIC_ANNOUNCEMENT
        && (
          <TopicAnnouncementButton
            triggerNextTournamentState={(data) => {
              setIsTournamentChangingState(true);
              triggerNextTournamentState(data);
            }}
            isTournamentChangingState={isTournamentChangingState}
          />
        )}

      {stateId === TOURNAMENT_STATES.QUESTION_ANNOUNCEMENT
        && (
        <QuestionAnnouncementButton
          triggerNextTournamentState={
            (data) => {
              setIsTournamentChangingState(true);
              triggerNextTournamentState(data);
            }
          }
          isTournamentChangingState={isTournamentChangingState}
        />
        )}

      {
        stateId === TOURNAMENT_STATES.STANDARD_QUESTION_NUMBER_CHOOSING
        && (
        <ChoseQuestionNumberModal
          questions={standardQuestions}
          onPick={(question) => {
            setIsTournamentChangingState(true);
            triggerNextTournamentState({ standardQuestionNumber: question.number });
          }}
        />
        )
      }

      {
        stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_NUMBER_CHOOSING
        && (
        <ChoseQuestionNumberModal
          questions={tiebreakQuestions}
          onPick={(question) => {
            setIsTournamentChangingState(true);
            triggerNextTournamentState({ tiebreakQuestionNumber: question.number });
          }}
        />
        )
      }

      { (stateId === TOURNAMENT_STATES.FIRST_BETTING
      || stateId === TOURNAMENT_STATES.SECOND_BETTING)
        && <BettingButtons /> }

      {(stateId === TOURNAMENT_STATES.RIGHT_QUESTION_CHECKING && user.id === tournament.owner.id)
        && <TimeLeftToAnswerProgress />}

      {(stateId === TOURNAMENT_STATES.RESULTS_ANNOUNCEMENT
      && user.id === tournament.owner.id)
        && <ChosePlayersModal withTimer={false} />}

      {(stateId === TOURNAMENT_STATES.RIGHT_QUESTION_CHECKING && user.id !== tournament.owner.id)
        && <EnterAnswer />}

      {stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANNOUNCEMENT
        && (
        <QuestionAnnouncementButton
          tiebreak
          triggerNextTournamentState={(data) => {
            setIsTournamentChangingState(true);
            triggerNextTournamentState(data);
          }}
          isTournamentChangingState={isTournamentChangingState}
        />
        )}

      {
        (
          stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANSWERING
          && user.id === tournament.owner.id
        )
        && <ChosePlayersModal tiebrake />
      }

      {
        (
          stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANSWERING
          && user.id !== tournament.owner.id
        )
        && <EnterAnswer />
      }
    </div>
  );
}

export default Controls;
