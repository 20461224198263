import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pusher from 'pusher-js';
import { TOURNAMENT_URL } from 'constants/apiRoutes';
import TOURNAMENT_STATUS from 'constants/tournamentStatus';
import axiosInstance from 'axiosInstance';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import useFetch from 'hooks/useFetch';

const useTournament = () => {
  const [tournament, setTournament] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isGameStarting, setIsGameStarting] = useState(false);
  const [isTournamentChangingState, setIsTournamentChangingState] = useState(false);

  const { tournamentId } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useFetch(`${TOURNAMENT_URL}/${tournamentId}`, {
    onSuccess: (data) => {
      const { tournament: tournamentData } = convertSnakeToCamelCase(data);

      if (tournamentData.handState === null
        && tournamentData.tournamentStatusId === TOURNAMENT_STATUS.IN_GAME) {
        window.location.reload();
      }
      setTournament(tournamentData);
      setIsSuccess(true);
    },
    onError: () => {
      navigate('/404');
    },
  });

  useEffect(() => {
    if (isSuccess === true) {
      // Pusher.logToConsole = true;

      const pusher = new Pusher(process.env.REACT_APP_KEY, {
        cluster: process.env.REACT_APP_CLUSTER,
      });

      const channel = pusher.subscribe(`quiz-game.${tournament.uniqueId}`);

      channel.bind('App\\Events\\UpdateTournament', (data) => {
        const { tournament } = convertSnakeToCamelCase(data);

        setTournament((prevTournament) => {
          const question = data?.question;
          let tournamentQuestions = [];

          if (question) {
            tournamentQuestions = prevTournament.questions.map((item) => {
              if (item.type === question.type && item.number === question.number) {
                return question;
              }
              return item;
            });
          }

          return ({
            ...prevTournament,
            ...tournament,
            questions: tournamentQuestions.length > 0
              ? tournamentQuestions
              : prevTournament.questions,
          }
          );
        });
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\StartTournament', () => {
        setIsGameStarting(true);

        axiosInstance.get(`${TOURNAMENT_URL}/${tournamentId}`).then((res) => {
          setTournament(convertSnakeToCamelCase(res.data.tournament));
        }).finally(() => {
          setIsGameStarting(false);
        });
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\UpdatePlayers', (data) => {
        const { players } = convertSnakeToCamelCase(data);
        setTournament((prevTournament) => ({
          ...prevTournament,
          players,
        }));
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\UpdateHistoryLog', (data) => {
        const { historyLog } = convertSnakeToCamelCase(data);
        setTournament((prevTournament) => ({
          ...prevTournament,
          historyLogs: [...prevTournament.historyLogs, historyLog],
        }));
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\UpdateTournamentState', (data) => {
        const { tournamentState } = convertSnakeToCamelCase(data);
        setTournament((prevTournament) => ({
          ...prevTournament,
          tournamentState,
        }));
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\UpdateHandState', (data) => {
        const { handState } = convertSnakeToCamelCase(data);
        setTournament((prevTournament) => ({
          ...prevTournament,
          handState,
        }));
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\UpdatePots', (data) => {
        const { pots } = convertSnakeToCamelCase(data);
        setTournament((prevTournament) => ({
          ...prevTournament,
          pots,
        }));
        setIsTournamentChangingState(false);
      });

      channel.bind('App\\Events\\GameOver', (data) => {
        const { winners } = convertSnakeToCamelCase(data);
        navigate(`/winner/${tournament.uniqueId}`, { state: { winners } });
      });

      channel.bind('App\\Events\\TiebreakQuestionSelector', (data) => {
        const { player } = convertSnakeToCamelCase(data);
        if (player) {
          localStorage.setItem('lastPlayerInSecondHand', JSON.stringify(player));
        }
        setIsTournamentChangingState(false);
      });
    }
  }, [isSuccess]);

  return {
    tournament,
    isLoading,
    isGameStarting,
    setTournament,
    isTournamentChangingState,
    setIsTournamentChangingState,
  };
};

export default useTournament;
