import React, {
  useState, useContext,
} from 'react';
import TextInputModal from 'components/Modals/TextInputModal';
import { announceQuestionButtonText } from 'constants/text/dashboard';
import { LoadingButton } from '@mui/lab';
import useIsAuto from 'hooks/useIsAuto';
import TournamentContext from 'context/TournamentContext';
import { Box } from '@mui/material';
import LoadedQuestionsForm from 'components/Forms/LoadedQuestionsForm';
import UploadedQuestionsContext from 'context/UploadedQuestionsContext';

function QuestionAnnouncementButton({
  isTournamentChangingState,
  triggerNextTournamentState,
  tiebreak = false,
}) {
  const [pickedQuestion, setPickedQuestion] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showLoadedQuestions, setShowLoadedQuestions] = useState(false);
  const { tournament } = useContext(TournamentContext);
  const { questions, markQuestionAsAsked } = useContext(UploadedQuestionsContext);

  useIsAuto({
    choseQuestionFunction: triggerNextTournamentState,
    tournament,
    tiebreak,
  });

  const openTextInputModal = () => {
    setShowModal(true);
  };

  const closeModal = (text) => {
    if (text === pickedQuestion?.text) {
      markQuestionAsAsked(pickedQuestion?.number);
    }
    setShowModal(false);

    if (tiebreak) {
      triggerNextTournamentState({
        tiebreakQuestion: text,
      });
      return;
    }

    triggerNextTournamentState({
      question: text,
    });
  };

  const onPickQuestion = (question) => {
    setPickedQuestion(question);
  };

  return (
    <>
      {showModal
      && (
      <TextInputModal
        headerText="Задай въпрос"
        onClose={closeModal}
        value={pickedQuestion?.text ?? ''}
        // extraButtons={
        //   <Button disabled={questions.length === 0}
        // onClick={() => { setShowLoadedQuestions(true); }} variant="contained" fullWidth>
        // Заредени въпроси
        // </Button>
        // }
      >
        {
        showLoadedQuestions
        && (
        <Box py={2}>
          <LoadedQuestionsForm questions={questions} onPick={onPickQuestion} />
        </Box>
        )
      }
      </TextInputModal>
      )}

      <LoadingButton
        onClick={openTextInputModal}
        loading={isTournamentChangingState}
        id="announce_question"
        type="button"
        fullWidth
        variant="contained"
      >
        {announceQuestionButtonText}
      </LoadingButton>
    </>
  );
}

export default QuestionAnnouncementButton;
