import {
  Button, Dialog, DialogTitle, Stack, DialogContent, TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

function TextInputModal({
  onClose,
  headerText,
  allowEmpty,
  minTextLength = 0,
  maxTextLength = 500,
  minRows = 6,
  maxRows = 12,
  extraButtons = [],
  textDescription = '',
  value = '',
  children,
  preventClose,
}) {
  const [error, setError] = useState();
  const [disable, setDisable] = useState(true);
  const [textValue, setTextValue] = useState(value);

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (textValue === '') {
      if (!allowEmpty) {
        setError('Полето е празно');
        return;
      }
    }

    if (textValue.length > maxTextLength) {
      setError(`Максималният брой позволен символи е ${maxTextLength}`);
      return;
    }

    if (onClose) {
      onClose(textValue);
    }
  };

  const updateText = (e) => {
    setTextValue(e.target.value);
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  useEffect(() => {
    if (textValue.length < minTextLength) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [textValue]);

  const handleClose = () => {
    if (preventClose) {
      return;
    }
    onClose();
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { updateText });
    }
    return child;
  });

  return (
    <Dialog
      open
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          overflowY: 'visible',
          minWidth: '30vw',
        },
        px: 2,
      }}
    >
      <DialogTitle textAlign="center">{headerText}</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <form
          onSubmit={onSubmit}
          style={{ position: 'relative' }}
        >
          {textDescription && <Typography>{textDescription}</Typography> }
          <TextField
            type="text"
            multiline
            maxRows={maxRows}
            minRows={minRows}
            fullWidth
            id="text_input_modal_value"
            onChange={updateText}
            value={textValue}
            sx={{ mb: 2 }}
          />
          {error && <Typography color="red">{error}</Typography>}
          {childrenWithProps}
          <Stack direction="row" spacing={2}>
            {
              (extraButtons && Array.isArray(extraButtons)) && extraButtons.map((button) => button)
            }
            {
              (extraButtons && !Array.isArray(extraButtons)) && extraButtons
            }
            <Button fullWidth variant="contained" type="submit" disabled={disable}>Продължи</Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>

  );
}

export default TextInputModal;
