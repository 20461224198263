import React, { useContext } from 'react';
import { callButtonText } from 'constants/text/dashboard';
import GAME_MOVES from 'constants/gameMoves';
import BettingContext from 'context/BettingContext';
import { LoadingButton } from '@mui/lab';
import useIsMobile from 'hooks/useIsMobile';

function CallButton({ buttonMobileStyles }) {
  const isMobile = useIsMobile();
  const {
    isTournamentChangingState, callValue, raise, handleMove,
  } = useContext(BettingContext);
  const isCallDisabled = callValue === 0 || callValue > raise.value;

  return (
    <LoadingButton
      onClick={() => handleMove({ move: GAME_MOVES.CALL })}
      disabled={isCallDisabled}
      loading={isTournamentChangingState}
      id="call_button"
      type="button"
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}
    >
      {callButtonText}
      {' '}
      {isCallDisabled === false ? `(${Math.floor(callValue)})` : callValue}
    </LoadingButton>
  );
}

export default CallButton;
