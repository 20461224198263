import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import { betButtonText } from 'constants/text/dashboard';
import GAME_MOVES from 'constants/gameMoves';
import { LoadingButton } from '@mui/lab';
import useIsMobile from 'hooks/useIsMobile';

function BetButton({ buttonMobileStyles }) {
  const isMobile = useIsMobile();
  const {
    raise,
    isTournamentChangingState,
    handleMove,
  } = useContext(BettingContext);

  return (
    <LoadingButton
      type="button"
      onClick={() => handleMove({ move: GAME_MOVES.BET, raiseValue: raise.value })}
      disabled={raise.isPossible === false}
      loading={isTournamentChangingState}
      id="bet_button"
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}
    >
      {betButtonText}
      {' '}
      (
      {Math.floor(raise.value)}
      )
    </LoadingButton>
  );
}

export default BetButton;
